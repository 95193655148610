<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          v-model="searchParam.name"
          label="课程名称"
          placeholder="请输入课程名称"
          clearable
        />
        <v-input
          v-model="searchParam.teacher"
          label="老师"
          placeholder="请输入老师姓名"
          clearable
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="show"
      width="50%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="课程名称">
          <v-input
            placeholder="请输入课程名称"
            clearable
            class="filter-item"
            v-model="form.name"
          ></v-input>
        </el-form-item>
        <el-form-item label="老师">
          <v-input
            placeholder="请输入老师姓名"
            clearable
            class="filter-item"
            v-model="form.teacher"
          ></v-input>
        </el-form-item>
        <el-form-item label="直播流类型">
          <v-radio :radioObj="typeOps" v-model="form.type"></v-radio>
        </el-form-item>
        <el-form-item label="直播流" v-if="form.type != null">
          <v-input
            placeholder="请输入直播流地址"
            clearable
            class="filter-item"
            v-model="form.livestreaming"
          ></v-input>
        </el-form-item>
        <el-form-item label="直播日期">
          <v-date-picker
            type="date"
            v-model="form.date"
            formatValue="yyyy-MM-dd"
            placeholder="请选择直播日期"
          ></v-date-picker>
        </el-form-item>
        <el-form-item label="直播时间">
          <v-time
            v-model="form.time"
            valueFormat="HH:mm"
            format="HH:mm"
            :pickerOptions="null"
            :width="190"
            is-range
          ></v-time>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button size="medium" @click="show = false" text="关闭"></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="submit"
          text="保存"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addOrUpdateInfoUrl,
  deleteByIdUrl,
  getListUrl,
  getDetailsUrl,
} from "./api.js";
export default {
  name: "liveBroadcastManage",
  data() {
    return {
      show: false,
      title: "",
      searchParam: {
        name: "",
        teacher: "",
      },
      typeOps: [
        { name: "1", value: "第三方跳转" },
        { name: "2", value: "直播流" },
      ],
      form: {
        id: null,
        type: null,
        name: "",
        teacher: "",
        date: null,
        time: null,
        livestreaming: "",
      },
      tableUrl: getListUrl,
      headers: [
        {
          prop: "name",
          label: "课程名称",
        },
        {
          prop: "teacher",
          label: "老师名称",
        },
        {
          prop: "date",
          label: "直播时间",
        },
      ],
    };
  },
  methods: {
    submit() {
      if (this.form.name == "") {
        this.$message.error("请输入课程名称！");
        return;
      }
      if (this.form.teacher == "") {
        this.$message.error("请输入老师姓名！");
        return;
      }
      if (this.form.type == null) {
        this.$message.error("请选择直播流类型！");
        return;
      }
      if (this.form.livestreaming == "") {
        this.$message.error("请输入直播流地址！");
        return;
      }
      if (this.form.date == null) {
        this.$message.error("请选择直播日期！");
        return;
      }
      if (this.form.time == null) {
        this.$message.error("请选择直播时间！");
        return;
      }
      let params = {
        id: this.form.id,
        type: this.form.type,
        name: this.form.name,
        teacher: this.form.teacher,
        date: this.form.date,
        time: this.form.time.join("-"),
        livestreaming: this.form.livestreaming,
      };
      this.$axios.post(`${addOrUpdateInfoUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.show = false;
          this.$message.success("操作成功");
          this.$refs.list.search();
        }
      });
    },
    toAdd() {
      this.form.id = null;
      this.form.type = null;
      this.form.name = "";
      this.form.teacher = "";
      this.form.date = null;
      this.form.time = "";
      this.form.livestreaming = "";
      this.show = true;
      this.title = "新增";
    },
    toEdit(item) {
      let params = {
        id: item.id,
      };
      this.$axios.get(`${getDetailsUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          this.form.id = res.data.id;
          this.form.type = String(res.data.type);
          this.form.name = res.data.name;
          this.form.teacher = res.data.teacher;
          this.form.date = res.data.date;
          this.form.time = res.data.time.split("-");
          this.form.livestreaming = res.data.livestreaming;
        }
      });
      this.show = true;
      this.title = "编辑";
    },
    toDelete(item) {
      this.$confirm(`是否删除当前直播数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios.post(deleteByIdUrl, null, { params }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
